import request from "@/request";
import { action, computed, observable } from "mobx";
import { globalStore } from "../index";
import {HOST} from "@/request/apiUrl";

class UserStore {
    @observable loading = false;
    @observable userInfo = {};
    @observable code = null;
    @observable redirect = null;
    @action async getUserInfo(isLogin) {
        this.loading = true;
        return request({
            api: "getUserInfo",
            params: {
                code: this.code,
                state: 'm'
            },
            callback: (res) => {
                this.loading = false;
                if (!res.code) {
                    this.userInfo = res || {};
                    this.toLink(isLogin);
                } else {
                    this.userInfo = {};
                }
                return this.userInfo;
            },
        });
    }

    @action toLink = (isLogin) => {
        if (this.redirect && this.redirect !== "null" && this.redirect !== "undefined") {
            console.log('toLink-redirect', this.redirect);
            window.location.href = this.redirect;
        } else if (isLogin) {
            globalStore.navigate("/search")
        }
    };
    @action logOut = () => {
        this.userInfo = {};
        this.loading = false;
        if (window.location.pathname.indexOf("/login") === -1) {
            window.location.replace("/login");
        }
    }

    // 是否为本地开发
    // @ts-ignore
    @computed get isLocalDev() {
        return !HOST && window.location.protocol === "http:";
    }
}

const userStore = new UserStore();
export default userStore;
