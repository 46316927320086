
// store
import {inject, observer} from "mobx-react";
import { useEffect, useState } from "react";
import { ConfigProvider, BackTop } from "@nutui/nutui-react";
import zh from "@nutui/nutui-react/dist/locales/zh-CN";
import RouterView from "@/router";
import {getQueryString, getDictsStr} from "@/utils/tool";
import {agentId, baseHostUrl, corpId} from "@/request/apiUrl";
import { useLocation, useNavigate } from 'react-router-dom';
import request from "@/request";
import * as ww from '@wecom/jssdk';
//components
import LoadingCom from "@/components/Loading";

const Layout = (props) => {
    const [isReady, setIsReady] = useState(false);
    const globalStore = props.store.globalStore;
    const userStore = props.store.userStore;
    const dictsStore = props.store.dictsStore;
    const { search, pathname } = useLocation();
    globalStore.navigate = useNavigate();

    useEffect(() =>{
        getData()
    }, [])

    //切换path的时候页面滚动至顶部
    useEffect(() => {
        const backTopDom = document.getElementById("backTop");
        if(backTopDom){
            backTopDom.scrollTo(0, 0);
        }
    },[pathname])

    const getData = async () => {
        userStore.redirect = decodeURIComponent(getQueryString(search, "redirect"));
        await userStore.getUserInfo();
        await dictsStore.getDicts();
        console.log("开始获取wwinfo");
        await getWwInfo();
        setIsReady(true);
    }
    
    const getSign = async (source) => {
        return request({
            api: "postQySign",
            method: "post",
            params: {url: window.location.href, source},
            loading: true,
            callback: (res, meta) => {
                return res;
            },
        });
    }
    //根据ticket生成jsapi签名
    //获取企业微信外部联系人/群聊信息
    const getWwInfo = async () => {
        // @ts-ignore
        // const ww = window.ww;
        ww.register({
            corpId: corpId, // 必填，当前用户企业所属企业ID
            agentId: agentId, //当前第三方应用 AgentID
            // suiteId: suiteId, // 应用套件 ID
            jsApiList: [
                'getContext',
                'getCurExternalContact',
                'getCurExternalChat',
                'getCurCorpGroupContact',
                'getCurCorpGroupChat',
            ],// 必填，需要使用的JSAPI列表
            //签名生成函数-企业jsapi_ticke
            async getConfigSignature() {
                const sign = await getSign('m');
                return sign;
            },
            async getAgentConfigSignature() {
                const agentSign = await getSign('m-agent');
                return agentSign;
            },
        })

        //获取入口参数entry
        ww.getContext({
            fail(res) {
                console.log('getContext fail', res);
            },
            //成功回调
            async success(result) {
                console.log('getContext-success-result', result);
                const {entry, errCode, errMsg} = result;
                //根据entry匹配获取id的api
                const getIdApi = getDictsStr("ww_entry_map", entry, {labelKey: "api"});
                console.log('getIdApi', getIdApi);
                if(getIdApi){
                    await ww[getIdApi]({
                         success: (result) => {
                            const {chatId, userId} = result;
                            globalStore.qyWechatInfo = {qywechat_chat_type: entry || "", qywechat_chat_id: chatId || userId || null}
                            console.log('获取企业微信会话result', result);
                        },
                        fail: (res) => {
                            console.log('fail-res', res);
                        },
                    })
                }
            },
        })
    }


    return(
        (isReady && (
            <div className="layout" id="layout" onClick={() => globalStore.handleHidePopver()}>
               <ConfigProvider locale={zh}>
                    <RouterView />
               </ConfigProvider>
               <BackTop target="backTop" />
            </div>
        )) || <LoadingCom loading={true} />
    )
}

export default inject("store")(observer(Layout));